import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axios';
import { 
  Box, 
  Button, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Collapse,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

// Row component for each log entry
function LogRow({ log }) {
  const [open, setOpen] = useState(false);
  
  // Format timestamp
  const formattedTime = new Date(log.timestamp).toLocaleTimeString();
  
  // Determine row color based on log level
  const getRowStyle = (level) => {
    switch(level) {
      case 'error':
        return { backgroundColor: 'rgba(255, 0, 0, 0.05)' };
      case 'warn':
        return { backgroundColor: 'rgba(255, 165, 0, 0.05)' };
      default:
        return {};
    }
  };
  
  // Get icon based on log level
  const getLevelIcon = (level) => {
    switch(level) {
      case 'error':
        return <ErrorIcon color="error" />;
      case 'warn':
        return <WarningIcon color="warning" />;
      default:
        return <InfoIcon color="info" />;
    }
  };
  
  // Format duration if available
  const getDuration = () => {
    if (log.data && log.data.duration) {
      const duration = parseInt(log.data.duration);
      if (isNaN(duration)) return log.data.duration;
      
      // Color code based on duration
      if (duration > 5000) {
        return <Chip label={log.data.duration} color="error" size="small" />;
      } else if (duration > 1000) {
        return <Chip label={log.data.duration} color="warning" size="small" />;
      } else {
        return <Chip label={log.data.duration} color="success" size="small" />;
      }
    }
    return '-';
  };
  
  return (
    <React.Fragment>
      <TableRow sx={getRowStyle(log.level)}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{getLevelIcon(log.level)}</TableCell>
        <TableCell>{formattedTime}</TableCell>
        <TableCell>{log.message}</TableCell>
        <TableCell>{log.data?.method || '-'}</TableCell>
        <TableCell>{log.data?.url || '-'}</TableCell>
        <TableCell>{log.data?.status || '-'}</TableCell>
        <TableCell>{getDuration()}</TableCell>
        <TableCell>{log.data?.requestId || '-'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <pre style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '10px', 
                borderRadius: '4px',
                overflow: 'auto',
                maxHeight: '300px'
              }}>
                {JSON.stringify(log.data, null, 2)}
              </pre>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Main component
function ApiLogViewer() {
  const [logs, setLogs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  
  // Load logs from localStorage
  const loadLogs = () => {
    setLogs(axiosInstance.getLogs());
  };
  
  // Clear all logs
  const clearLogs = () => {
    axiosInstance.clearLogs();
    setLogs([]);
    setOpenDialog(false);
  };
  
  // Load logs on component mount
  useEffect(() => {
    loadLogs();
    
    // Set up interval to refresh logs every 5 seconds
    const interval = setInterval(loadLogs, 5000);
    
    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5" component="h2">
          API Request Logs
        </Typography>
        <Box>
          <Button 
            startIcon={<RefreshIcon />} 
            onClick={loadLogs}
            sx={{ mr: 1 }}
          >
            Refresh
          </Button>
          <Button 
            startIcon={<DeleteIcon />} 
            color="error"
            onClick={() => setOpenDialog(true)}
          >
            Clear Logs
          </Button>
        </Box>
      </Box>
      
      {logs.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', my: 4 }}>
          No logs available. Make some API requests to see logs here.
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="api logs table" size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Level</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Request ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <LogRow key={index} log={log} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Clear Logs</DialogTitle>
        <DialogContent>
          Are you sure you want to clear all API logs? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={clearLogs} color="error">Clear All Logs</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ApiLogViewer;