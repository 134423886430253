import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Alert,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Security as SecurityIcon, QrCode2 as QrCodeIcon } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../api/axios';

const TwoFactorSettings = () => {
  const { currentUser, authToken } = useAuth();
  const [status, setStatus] = useState({ enabled: false, verified: false });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [step, setStep] = useState(0); // 0: Initial, 1: Setup, 2: Verify
  
  // Setup state
  const [setupData, setSetupData] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  
  // Disable state
  const [disableDialogOpen, setDisableDialogOpen] = useState(false);
  const [disablePassword, setDisablePassword] = useState('');
  const [disableLoading, setDisableLoading] = useState(false);
  
  // Fetch 2FA status on component mount
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get('/2fa-status');
        setStatus(response.data);
      } catch (error) {
        setError('Failed to fetch 2FA status');
      } finally {
        setLoading(false);
      }
    };
    
    fetchStatus();
  }, []);
  
  // Start 2FA setup
  const handleSetup = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    
    try {
      const response = await axiosInstance.post('/setup-2fa');
      
      setSetupData(response.data);
      setStep(1); // Move to setup step
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to setup 2FA');
    } finally {
      setLoading(false);
    }
  };
  
  // Verify 2FA setup
  const handleVerify = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);
    
    try {
      const response = await axiosInstance.post('/verify-2fa-setup', {
        code: verificationCode
      });
      
      setStatus(response.data);
      setSuccess('Two-factor authentication has been successfully enabled');
      setStep(0); // Back to initial step
      setVerificationCode('');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to verify 2FA code');
    } finally {
      setLoading(false);
    }
  };
  
  // Disable 2FA
  const handleDisable = async () => {
    setError('');
    setDisableLoading(true);
    
    try {
      const response = await axiosInstance.post('/disable-2fa', {
        password: disablePassword
      });
      
      setStatus(response.data);
      setSuccess('Two-factor authentication has been disabled');
      setDisableDialogOpen(false);
      setDisablePassword('');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to disable 2FA');
    } finally {
      setDisableLoading(false);
    }
  };
  
  if (loading && step === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <SecurityIcon sx={{ fontSize: 32, mr: 2, color: 'primary.main' }} />
            <Typography variant="h5">Two-Factor Authentication</Typography>
          </Box>
          
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {success}
            </Alert>
          )}
          
          {step === 0 && (
            <>
              <Paper sx={{ p: 3, mb: 3, bgcolor: 'background.default' }}>
                <Typography variant="h6" gutterBottom>
                  Status: {status.enabled ? (
                    <span style={{ color: '#4caf50' }}>Enabled</span>
                  ) : (
                    <span style={{ color: '#f44336' }}>Disabled</span>
                  )}
                </Typography>
                
                <Typography variant="body1" paragraph>
                  Two-factor authentication adds an extra layer of security to your account.
                  When enabled, you'll need to provide both your password and a verification code
                  from your authenticator app when signing in.
                </Typography>
                
                {status.enabled ? (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setDisableDialogOpen(true)}
                  >
                    Disable 2FA
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSetup}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Enable 2FA'}
                  </Button>
                )}
              </Paper>
              
              <Typography variant="subtitle2" color="textSecondary">
                We recommend using authenticator apps like Google Authenticator, Authy, or Microsoft Authenticator.
              </Typography>
            </>
          )}
          
          {step === 1 && setupData && (
            <>
              <Stepper activeStep={0} sx={{ mb: 4 }}>
                <Step>
                  <StepLabel>Setup</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Verify</StepLabel>
                </Step>
              </Stepper>
              
              <Typography variant="h6" gutterBottom>
                Scan QR Code
              </Typography>
              
              <Typography variant="body1" paragraph>
                Scan this QR code with your authenticator app, or manually enter the secret key.
              </Typography>
              
              <Box display="flex" justifyContent="center" mb={3}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: 'white' }}>
                  <img
                    src={setupData.qrCode}
                    alt="2FA QR Code"
                    style={{ width: 200, height: 200 }}
                  />
                </Paper>
              </Box>
              
              <Typography variant="subtitle2" gutterBottom>
                Manual entry secret:
              </Typography>
              
              <Paper sx={{ p: 2, mb: 4, bgcolor: 'background.default' }}>
                <Typography
                  variant="body2"
                  fontFamily="monospace"
                  letterSpacing={1}
                >
                  {setupData.secret}
                </Typography>
              </Paper>
              
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setStep(0);
                    setSetupData(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStep(2)}
                >
                  Continue
                </Button>
              </Box>
            </>
          )}
          
          {step === 2 && (
            <>
              <Stepper activeStep={1} sx={{ mb: 4 }}>
                <Step>
                  <StepLabel>Setup</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Verify</StepLabel>
                </Step>
              </Stepper>
              
              <Typography variant="h6" gutterBottom>
                Verify Setup
              </Typography>
              
              <Typography variant="body1" paragraph>
                Enter the 6-digit verification code from your authenticator app to complete the setup.
              </Typography>
              
              <form onSubmit={handleVerify}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="verificationCode"
                  label="Verification Code"
                  type="text"
                  id="verificationCode"
                  autoComplete="one-time-code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  inputProps={{
                    maxLength: 6,
                    pattern: '[0-9]*',
                    inputMode: 'numeric'
                  }}
                  sx={{ mb: 3 }}
                />
                
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading || verificationCode.length !== 6}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Verify & Enable'}
                  </Button>
                </Box>
              </form>
            </>
          )}
        </CardContent>
      </Card>
      
      {/* Disable 2FA Dialog */}
      <Dialog open={disableDialogOpen} onClose={() => setDisableDialogOpen(false)}>
        <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph sx={{ mt: 1 }}>
            Are you sure you want to disable two-factor authentication? This will make your account less secure.
          </Typography>
          
          <Typography variant="body2" paragraph>
            Please enter your password to confirm:
          </Typography>
          
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="disable-password"
            autoComplete="current-password"
            value={disablePassword}
            onChange={(e) => setDisablePassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisableDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleDisable}
            color="error"
            variant="contained"
            disabled={disableLoading || !disablePassword}
          >
            {disableLoading ? <CircularProgress size={24} /> : 'Disable 2FA'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TwoFactorSettings;