import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosInstance from '../api/axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authToken, setAuthToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [sessionExpiry, setSessionExpiry] = useState(null);

  // Session timeout - 1 hour (in milliseconds)
  const SESSION_TIMEOUT = 60 * 60 * 1000;

  // Initialize auth state from localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userStr = localStorage.getItem('user');
    const expiry = localStorage.getItem('sessionExpiry');
    
    if (token && userStr && expiry) {
      const expiryTime = parseInt(expiry, 10);
      
      // Check if the session has expired
      if (Date.now() > expiryTime) {
        // Session expired, log out
        logout();
      } else {
        // Session still valid
        setAuthToken(token);
        setCurrentUser(JSON.parse(userStr));
        setAuthenticated(true);
        setSessionExpiry(expiryTime);
        
        // Set up session expiry timer
        const timeRemaining = expiryTime - Date.now();
        setupSessionTimeout(timeRemaining);
      }
    }
    
    setLoading(false);
  }, []);

  // Set up session timeout
  const setupSessionTimeout = (timeRemaining) => {
    const timer = setTimeout(() => {
      logout();
    }, timeRemaining);
    
    // Clean up timer on unmount
    return () => clearTimeout(timer);
  };

  // Refresh session - reset the expiry time
  const refreshSession = () => {
    const newExpiry = Date.now() + SESSION_TIMEOUT;
    localStorage.setItem('sessionExpiry', newExpiry.toString());
    setSessionExpiry(newExpiry);
    
    // Reset the timeout
    setupSessionTimeout(SESSION_TIMEOUT);
  };

  // Login function
  const login = async (credentials) => {
    const response = await axiosInstance.post('/login', credentials);
    
    if (response.data.requireTwoFactor) {
      // Return temp token for 2FA verification
      return { requireTwoFactor: true, tempToken: response.data.tempToken };
    }
    
    // No 2FA required, proceed with login
    const { token, user } = response.data;
    const expiry = Date.now() + SESSION_TIMEOUT;
    
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('sessionExpiry', expiry.toString());
    
    setAuthToken(token);
    setCurrentUser(user);
    setAuthenticated(true);
    setSessionExpiry(expiry);
    
    // Set up session timeout
    setupSessionTimeout(SESSION_TIMEOUT);
    
    return { success: true };
  };

  // Verify 2FA
  const verifyTwoFactor = async (tempToken, code) => {
    const response = await axiosInstance.post('/verify-2fa', {
      token: tempToken,
      code
    });
    
    const { token, user } = response.data;
    const expiry = Date.now() + SESSION_TIMEOUT;
    
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('sessionExpiry', expiry.toString());
    
    setAuthToken(token);
    setCurrentUser(user);
    setAuthenticated(true);
    setSessionExpiry(expiry);
    
    // Set up session timeout
    setupSessionTimeout(SESSION_TIMEOUT);
    
    return { success: true };
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('sessionExpiry');
    
    setAuthToken('');
    setCurrentUser(null);
    setAuthenticated(false);
    setSessionExpiry(null);
  };

  // Register new user
  const registerUser = async (userData) => {
    console.log('Creating user with data:', userData);
    console.log('Using auth token:', authToken);
    try {
      // Add a longer timeout for the user creation request (60 seconds)
      const response = await axiosInstance.post('/users', userData, {
        headers: { Authorization: `Bearer ${authToken}` },
        timeout: 60000 // 60 seconds timeout
      });
      console.log('User creation response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error.response?.data || error.message);
      throw error;
    }
  };

  // Update user
  const updateUser = async (userId, userData) => {
    const response = await axiosInstance.put(`/users/${userId}`, userData, {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    return response.data;
  };

  // Get all users
  const getUsers = async () => {
    const response = await axiosInstance.get('/users', {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    return response.data;
  };

  // Reset user password (admin function)
  const resetUserPassword = async (userId, newPassword) => {
    const response = await axiosInstance.post(`/users/${userId}/reset-password`, {
      newPassword
    }, {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    return response.data;
  };

  // Request password reset (user function)
  const requestPasswordReset = async (email) => {
    const response = await axiosInstance.post('/request-password-reset', {
      email
    });
    return response.data;
  };

  // Complete password reset with token
  const completePasswordReset = async (token, newPassword) => {
    const response = await axiosInstance.post('/complete-password-reset', {
      token,
      newPassword
    });
    return response.data;
  };

  // Reset MFA for a user (admin function)
  const resetUserMFA = async (userId) => {
    const response = await axiosInstance.post(`/users/${userId}/reset-mfa`, {}, {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    return response.data;
  };

  const value = {
    currentUser,
    authToken,
    authenticated,
    loading,
    sessionExpiry,
    login,
    logout,
    verifyTwoFactor,
    refreshSession,
    registerUser,
    createUser: registerUser, // Add alias for createUser to match what UserManagement expects
    updateUser,
    getUsers,
    resetUserPassword,
    requestPasswordReset,
    completePasswordReset,
    resetUserMFA
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};