import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Divider,
  Link,
  Tabs,
  Tab,
} from '@mui/material';
import { Lock as LockIcon, Security as SecurityIcon, Email as EmailIcon } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const { login, verifyTwoFactor, requestPasswordReset } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [tempToken, setTempToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0); // 0: Login, 1: 2FA
  const [tabValue, setTabValue] = useState(0); // 0: Login, 1: Forgot Password
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError('');
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const result = await login({ email, password });
      
      // Check if 2FA is required
      if (result.requireTwoFactor) {
        setTempToken(result.tempToken);
        setStep(1); // Move to 2FA step
      }
      // If success, the auth context will update and redirect automatically
    } catch (error) {
      setError(
        error.response?.data?.message ||
        'Failed to login. Please check your credentials and try again.'
      );
    } finally {
      setLoading(false);
    }
  };
  
  const handleTwoFactorSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      await verifyTwoFactor(tempToken, twoFactorCode);
      // If success, the auth context will update and redirect automatically
    } catch (error) {
      setError(
        error.response?.data?.message ||
        'Failed to verify 2FA code. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setTabValue(1);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '90%',
          borderRadius: 2,
          boxShadow: theme.shadows[20],
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
          }}
        >
          <Box
            sx={{
              width: 60,
              height: 60,
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            {step === 0 ? (
              <LockIcon sx={{ fontSize: 32, color: 'white' }} />
            ) : (
              <SecurityIcon sx={{ fontSize: 32, color: 'white' }} />
            )}
          </Box>

          <Typography variant="h4" component="h1" gutterBottom>
            {step === 0 ? 'Welcome Back' : 'Two-Factor Authentication'}
          </Typography>
          
          <Typography
            variant="subtitle1"
            color="textSecondary"
            align="center"
            sx={{ mb: 3 }}
          >
            Firearm License Notification System
          </Typography>

          {step === 0 ? (
            // Login/Forgot Password Tabs
            <>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{ width: '100%', mb: 3 }}
              >
                <Tab label="Login" />
                <Tab label="Forgot Password" />
              </Tabs>

              {error && (
                <Alert
                  severity="error"
                  sx={{
                    width: '100%',
                    mb: 2,
                  }}
                >
                  {error}
                </Alert>
              )}
              
              {success && (
                <Alert
                  severity="success"
                  sx={{
                    width: '100%',
                    mb: 2,
                  }}
                >
                  {success}
                </Alert>
              )}

              {tabValue === 0 ? (
                // Login Tab
                <form onSubmit={handleLoginSubmit} style={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 2 }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={loading}
                    sx={{
                      py: 1.5,
                      mt: 2,
                      mb: 2,
                      position: 'relative',
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    ) : (
                      'Sign In'
                    )}
                  </Button>
                  
                  <Box sx={{ textAlign: 'center', mt: 1 }}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={handleForgotPassword}
                      underline="hover"
                    >
                      Forgot password?
                    </Link>
                  </Box>
                </form>
              ) : (
                // Forgot Password Tab
                <Box sx={{ width: '100%' }}>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    Enter your email address and we'll send you a link to reset your password.
                  </Typography>
                  
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="reset-email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ mt: 2, mb: 2 }}
                    startIcon={<EmailIcon />}
                    onClick={async () => {
                      if (!email) {
                        setError('Email is required');
                        return;
                      }
                      
                      setLoading(true);
                      try {
                        await requestPasswordReset(email);
                        setError('');
                        setSuccess('Password reset link sent. Please check your email.');
                      } catch (error) {
                        setError(
                          error.response?.data?.message ||
                          'Failed to send reset link. Please try again.'
                        );
                      } finally {
                        setLoading(false);
                      }
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    ) : (
                      'Send Reset Link'
                    )}
                  </Button>
                  
                  <Box sx={{ textAlign: 'center', mt: 1 }}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setTabValue(0)}
                      underline="hover"
                    >
                      Back to login
                    </Link>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            // 2FA Step
            <>
              {/* Stepper */}
              <Stepper activeStep={1} sx={{ width: '100%', mb: 3 }}>
                <Step>
                  <StepLabel>Password</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Verification</StepLabel>
                </Step>
              </Stepper>

              {error && (
                <Alert
                  severity="error"
                  sx={{
                    width: '100%',
                    mb: 2,
                  }}
                >
                  {error}
                </Alert>
              )}
              
              <form onSubmit={handleTwoFactorSubmit} style={{ width: '100%' }}>
                <Paper sx={{ p: 2, mb: 3, bgcolor: 'background.default' }}>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Enter the 6-digit code from your authenticator app
                  </Typography>
                </Paper>
                
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="twoFactorCode"
                  label="Authentication Code"
                  type="text"
                  id="twoFactorCode"
                  autoComplete="one-time-code"
                  value={twoFactorCode}
                  onChange={(e) => setTwoFactorCode(e.target.value)}
                  inputProps={{
                    maxLength: 6,
                    pattern: '[0-9]*',
                    inputMode: 'numeric'
                  }}
                  sx={{ mb: 2 }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setStep(0);
                      setTwoFactorCode('');
                      setTempToken('');
                    }}
                    disabled={loading}
                  >
                    Back
                  </Button>
                  
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading || twoFactorCode.length !== 6}
                    sx={{ position: 'relative' }}
                  >
                    {loading ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    ) : (
                      'Verify & Sign In'
                    )}
                  </Button>
                </Box>
              </form>
            </>
          )}

          <Divider sx={{ width: '100%', my: 3 }} />
          
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
          >
            Staff Access Only
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
