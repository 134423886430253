import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  Divider,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip
} from '@mui/material';
import {
  Person as PersonIcon,
  Security as SecurityIcon,
  Check as CheckIcon,
  QrCode as QrCodeIcon,
  Key as KeyIcon
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';

const UserProfile = () => {
  const { currentUser, updateUser, setupTwoFactor, verifyTwoFactorSetup, disableTwoFactor } = useAuth();
  
  // Profile form state
  const [profileForm, setProfileForm] = useState({
    name: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  
  // MFA setup state
  const [mfaSetup, setMfaSetup] = useState({
    qrCode: '',
    secret: '',
    verificationCode: '',
    activeStep: 0
  });
  
  // Dialog states
  const [openDisableMfaDialog, setOpenDisableMfaDialog] = useState(false);
  const [disableMfaPassword, setDisableMfaPassword] = useState('');
  
  // UI states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Initialize form with current user data
  useEffect(() => {
    if (currentUser) {
      setProfileForm({
        ...profileForm,
        name: currentUser.name || '',
        email: currentUser.email || ''
      });
    }
  }, [currentUser]);
  
  // Handle profile form input changes
  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setProfileForm({
      ...profileForm,
      [name]: value
    });
  };
  
  // Handle MFA verification code input
  const handleVerificationCodeChange = (e) => {
    setMfaSetup({
      ...mfaSetup,
      verificationCode: e.target.value
    });
  };
  
  // Handle disable MFA password input
  const handleDisableMfaPasswordChange = (e) => {
    setDisableMfaPassword(e.target.value);
  };
  
  // Update profile
  const handleUpdateProfile = async () => {
    // Validate form
    if (!profileForm.name) {
      setError('Name is required');
      return;
    }
    
    if (!profileForm.email) {
      setError('Email is required');
      return;
    }
    
    // Check if password is being changed
    if (profileForm.newPassword || profileForm.confirmPassword) {
      if (!profileForm.currentPassword) {
        setError('Current password is required to change password');
        return;
      }
      
      if (profileForm.newPassword !== profileForm.confirmPassword) {
        setError('New passwords do not match');
        return;
      }
    }
    
    setLoading(true);
    setError('');
    
    try {
      const userData = {
        name: profileForm.name,
        email: profileForm.email
      };
      
      // Include password if it's being changed
      if (profileForm.newPassword) {
        userData.currentPassword = profileForm.currentPassword;
        userData.password = profileForm.newPassword;
      }
      
      await updateUser(currentUser.id, userData);
      
      setSuccess('Profile updated successfully');
      
      // Clear password fields
      setProfileForm({
        ...profileForm,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update profile');
      console.error('Error updating profile:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Start MFA setup
  const handleStartMfaSetup = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await setupTwoFactor();
      
      setMfaSetup({
        ...mfaSetup,
        qrCode: response.qrCode,
        secret: response.secret,
        activeStep: 1
      });
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to start MFA setup');
      console.error('Error starting MFA setup:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Verify MFA setup
  const handleVerifyMfaSetup = async () => {
    if (!mfaSetup.verificationCode || mfaSetup.verificationCode.length !== 6) {
      setError('Please enter a valid 6-digit verification code');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      await verifyTwoFactorSetup(mfaSetup.verificationCode);
      
      setMfaSetup({
        ...mfaSetup,
        activeStep: 2
      });
      
      setSuccess('Two-factor authentication enabled successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to verify MFA setup');
      console.error('Error verifying MFA setup:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Open disable MFA dialog
  const handleOpenDisableMfaDialog = () => {
    setOpenDisableMfaDialog(true);
    setDisableMfaPassword('');
    setError('');
  };
  
  // Close disable MFA dialog
  const handleCloseDisableMfaDialog = () => {
    setOpenDisableMfaDialog(false);
  };
  
  // Disable MFA
  const handleDisableMfa = async () => {
    if (!disableMfaPassword) {
      setError('Password is required');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      await disableTwoFactor(disableMfaPassword);
      
      setSuccess('Two-factor authentication disabled successfully');
      handleCloseDisableMfaDialog();
      
      // Reset MFA setup state
      setMfaSetup({
        qrCode: '',
        secret: '',
        verificationCode: '',
        activeStep: 0
      });
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to disable MFA');
      console.error('Error disabling MFA:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Clear success message after 5 seconds
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess('');
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [success]);
  
  if (!currentUser) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        User Profile
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      <Grid container spacing={3}>
        {/* Profile Information */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  <PersonIcon />
                </Avatar>
              }
              title="Profile Information"
              subheader="Update your personal information"
            />
            <Divider />
            <CardContent>
              <TextField
                margin="normal"
                fullWidth
                label="Full Name"
                name="name"
                value={profileForm.name}
                onChange={handleProfileInputChange}
                required
              />
              
              <TextField
                margin="normal"
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                value={profileForm.email}
                onChange={handleProfileInputChange}
                required
              />
              
              <Divider sx={{ my: 3 }} />
              
              <Typography variant="subtitle1" gutterBottom>
                Change Password
              </Typography>
              
              <TextField
                margin="normal"
                fullWidth
                label="Current Password"
                name="currentPassword"
                type="password"
                value={profileForm.currentPassword}
                onChange={handleProfileInputChange}
              />
              
              <TextField
                margin="normal"
                fullWidth
                label="New Password"
                name="newPassword"
                type="password"
                value={profileForm.newPassword}
                onChange={handleProfileInputChange}
              />
              
              <TextField
                margin="normal"
                fullWidth
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                value={profileForm.confirmPassword}
                onChange={handleProfileInputChange}
              />
              
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProfile}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  Save Changes
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Two-Factor Authentication */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: currentUser.mfaEnabled ? 'success.main' : 'warning.main' }}>
                  <SecurityIcon />
                </Avatar>
              }
              title="Two-Factor Authentication"
              subheader={currentUser.mfaEnabled ? 'Currently enabled' : 'Currently disabled'}
              action={
                currentUser.mfaEnabled && (
                  <Chip
                    label="Enabled"
                    color="success"
                    icon={<CheckIcon />}
                    sx={{ mt: 1, mr: 1 }}
                  />
                )
              }
            />
            <Divider />
            <CardContent>
              {currentUser.mfaEnabled ? (
                <>
                  <Typography variant="body1" paragraph>
                    Two-factor authentication is currently enabled for your account. This adds an extra layer of security by requiring a verification code from your authenticator app when you log in.
                  </Typography>
                  
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleOpenDisableMfaDialog}
                    startIcon={<SecurityIcon />}
                  >
                    Disable Two-Factor Authentication
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="body1" paragraph>
                    Two-factor authentication adds an extra layer of security to your account by requiring a verification code from your authenticator app when you log in.
                  </Typography>
                  
                  <Stepper activeStep={mfaSetup.activeStep} orientation="vertical">
                    <Step>
                      <StepLabel>Start Setup</StepLabel>
                      <StepContent>
                        <Typography variant="body2" color="text.secondary" paragraph>
                          To get started, you'll need an authenticator app like Google Authenticator, Microsoft Authenticator, or Authy.
                        </Typography>
                        
                        <Button
                          variant="contained"
                          onClick={handleStartMfaSetup}
                          disabled={loading}
                          startIcon={loading ? <CircularProgress size={20} /> : <SecurityIcon />}
                        >
                          Start Setup
                        </Button>
                      </StepContent>
                    </Step>
                    
                    <Step>
                      <StepLabel>Scan QR Code</StepLabel>
                      <StepContent>
                        <Typography variant="body2" color="text.secondary" paragraph>
                          Scan this QR code with your authenticator app, or enter the setup key manually.
                        </Typography>
                        
                        {mfaSetup.qrCode && (
                          <Box sx={{ textAlign: 'center', my: 2 }}>
                            <img
                              src={`data:image/png;base64,${mfaSetup.qrCode}`}
                              alt="QR Code for 2FA setup"
                              style={{ maxWidth: '200px' }}
                            />
                          </Box>
                        )}
                        
                        {mfaSetup.secret && (
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Manual Setup Key:
                            </Typography>
                            <Paper
                              sx={{
                                p: 1,
                                backgroundColor: 'grey.100',
                                fontFamily: 'monospace',
                                wordBreak: 'break-all'
                              }}
                            >
                              {mfaSetup.secret}
                            </Paper>
                          </Box>
                        )}
                        
                        <Typography variant="body2" color="text.secondary" paragraph>
                          After scanning, enter the 6-digit verification code from your app:
                        </Typography>
                        
                        <TextField
                          margin="normal"
                          label="Verification Code"
                          value={mfaSetup.verificationCode}
                          onChange={handleVerificationCodeChange}
                          inputProps={{
                            maxLength: 6,
                            pattern: '[0-9]*',
                            inputMode: 'numeric'
                          }}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        
                        <Button
                          variant="contained"
                          onClick={handleVerifyMfaSetup}
                          disabled={loading || mfaSetup.verificationCode.length !== 6}
                          startIcon={loading ? <CircularProgress size={20} /> : <CheckIcon />}
                        >
                          Verify & Enable
                        </Button>
                      </StepContent>
                    </Step>
                    
                    <Step>
                      <StepLabel>Setup Complete</StepLabel>
                      <StepContent>
                        <Typography variant="body2" color="text.secondary" paragraph>
                          Two-factor authentication has been successfully enabled for your account.
                        </Typography>
                        
                        <Alert severity="success" sx={{ mb: 2 }}>
                          Your account is now more secure!
                        </Alert>
                        
                        <Typography variant="body2" color="text.secondary" paragraph>
                          From now on, you'll need to enter a verification code from your authenticator app when you log in.
                        </Typography>
                      </StepContent>
                    </Step>
                  </Stepper>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Disable MFA Dialog */}
      <Dialog open={openDisableMfaDialog} onClose={handleCloseDisableMfaDialog}>
        <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable two-factor authentication? This will make your account less secure.
          </DialogContentText>
          
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={disableMfaPassword}
            onChange={handleDisableMfaPasswordChange}
            required
            sx={{ mt: 2 }}
          />
          
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisableMfaDialog}>Cancel</Button>
          <Button
            onClick={handleDisableMfa}
            color="error"
            variant="contained"
            disabled={loading || !disableMfaPassword}
          >
            {loading ? <CircularProgress size={24} /> : 'Disable 2FA'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserProfile;