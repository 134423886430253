import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Layout from './components/Layout';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import TestNotifications from './components/TestNotifications';
import NotificationsLog from './components/NotificationsLog';
import UpcomingNotifications from './components/UpcomingNotifications';
import ScheduleSettings from './components/ScheduleSettings';
import SystemMetrics from './components/SystemMetrics';
import TwoFactorSettings from './components/TwoFactorSettings';
import UserManagement from './components/UserManagement';
import UserProfile from './components/UserProfile';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { AuthProvider, useAuth } from './context/AuthContext';

// Create a client for React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
      retry: 3,
    },
  },
});

// Main application content
const AppContent = () => {
  const { authenticated, authToken, loading } = useAuth();
  const [currentSection, setCurrentSection] = useState('Dashboard');

  const renderSection = () => {
    switch (currentSection) {
      case 'Dashboard':
        return <Dashboard />;
      case 'TestNotifications':
        return <TestNotifications />;
      case 'NotificationsLog':
        return <NotificationsLog />;
      case 'UpcomingNotifications':
        return <UpcomingNotifications />;
      case 'ScheduleSettings':
        return <ScheduleSettings />;
      case 'SystemMetrics':
        return <SystemMetrics />;
      case 'TwoFactorSettings':
        return <TwoFactorSettings />;
      case 'UserManagement':
        return <UserManagement />;
      case 'UserProfile':
        return <UserProfile />;
      default:
        return (
          <div style={{ padding: 20, textAlign: 'center' }}>
            <h2>Coming Soon</h2>
            <p>This feature is currently being implemented.</p>
          </div>
        );
    }
  };

  // Show loading state
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        Loading...
      </div>
    );
  }

  return (
    <>
      {authenticated ? (
        <Layout currentSection={currentSection} setCurrentSection={setCurrentSection}>
          {renderSection()}
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
