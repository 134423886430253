import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Chip,
  Box,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@mui/material';
import { Refresh as RefreshIcon, Send as SendIcon } from '@mui/icons-material';
import axiosInstance from '../api/axios';
import dayjs from 'dayjs';

function UpcomingNotifications({ authToken }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [triggerLoading, setTriggerLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchNotifications = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/notification/scheduled', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setNotifications(response.data);
    } catch (error) {
      setError('Failed to fetch upcoming notifications. Please try again later.');
      console.error('Fetch Upcoming Notifications Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCalculateNotifications = async () => {
    setCalculateLoading(true);
    try {
      const response = await axiosInstance.post('/notification/calculate', {}, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      
      setSnackbarSeverity('success');
      setSnackbarMessage(
        response.data.notificationCount > 0
          ? `Successfully calculated ${response.data.notificationCount} notifications`
          : 'Calculation completed. No notifications are currently due.'
      );
      setSnackbarOpen(true);
      
      // Refresh the notifications list after calculation
      fetchNotifications();
    } catch (error) {
      console.error('Calculate Notifications Error:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(
        error.response?.data?.message ||
        'Failed to calculate notifications. Please check the logs for details.'
      );
      setSnackbarOpen(true);
    } finally {
      setCalculateLoading(false);
    }
  };

  const handleTriggerTask = async () => {
    setTriggerLoading(true);
    try {
      const response = await axiosInstance.post('/notification/trigger-task', {}, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      
      setSnackbarSeverity('success');
      setSnackbarMessage(
        response.data.notificationCount > 0
          ? `Successfully sent ${response.data.notificationCount} notifications`
          : 'Task executed successfully, but no notifications were due to be sent'
      );
      setSnackbarOpen(true);
      
      // Refresh the notifications list after triggering
      fetchNotifications();
    } catch (error) {
      console.error('Trigger Notification Task Error:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(
        error.response?.data?.message ||
        'Failed to trigger notification task. Please check the logs for details.'
      );
      setSnackbarOpen(true);
    } finally {
      setTriggerLoading(false);
      setConfirmDialogOpen(false);
    }
  };

  const openConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, []); // Fetch on component mount

  const getMethodChips = (methods) => {
    if (!Array.isArray(methods)) return <Chip label="N/A" size="small" />;

    return (
      <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
        {methods.map((method, index) => (
          <Chip
            key={index}
            label={method}
            size="small"
            color={
              method === 'email' ? 'primary' :
              method === 'sms' ? 'secondary' :
              'default'
            }
          />
        ))}
      </Box>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">
          Upcoming Notifications
        </Typography>
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={handleCalculateNotifications}
            disabled={calculateLoading || loading || triggerLoading}
            sx={{ mr: 1 }}
          >
            {calculateLoading ? 'Calculating...' : 'Calculate Notifications'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            onClick={openConfirmDialog}
            disabled={triggerLoading || loading || calculateLoading}
            sx={{ mr: 1 }}
          >
            {triggerLoading ? 'Sending...' : 'Send Notifications'}
          </Button>
          <Tooltip title="Refresh">
            <IconButton
              onClick={fetchNotifications}
              disabled={loading || triggerLoading || calculateLoading}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : notifications.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Methods</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Scheduled For</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((notification, index) => (
                <TableRow key={index} hover>
                  <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {notification.userId}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={notification.notificationType}
                      size="small"
                      color="info"
                    />
                  </TableCell>
                  <TableCell sx={{ maxWidth: 300 }}>
                    {notification.message}
                  </TableCell>
                  <TableCell>
                    {getMethodChips(notification.notification_methods)}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={notification.subscription_level || 'Standard'}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {notification.timestamp
                      ? dayjs(notification.timestamp).format('MMM D, YYYY h:mm A')
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box textAlign="center" py={3}>
          <Typography color="textSecondary">
            No upcoming notifications scheduled.
          </Typography>
        </Box>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send Notifications"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will send all calculated notifications immediately.
            {notifications.length > 0 ?
              ` There are currently ${notifications.length} notifications ready to be sent.` :
              ' There are currently no notifications ready to be sent.'}
            <br /><br />
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleTriggerTask}
            color="primary"
            variant="contained"
            autoFocus
            disabled={triggerLoading}
          >
            {triggerLoading ? 'Sending...' : 'Send Notifications'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default UpcomingNotifications;
