import axios from 'axios';

// Create a custom logger for frontend
const logger = {
  log: (message, data) => {
    console.log(`[INFO] ${message}`, data);
    // Also log to a persistent storage if needed
    try {
      const logs = JSON.parse(localStorage.getItem('api_logs') || '[]');
      logs.unshift({
        level: 'info',
        timestamp: new Date().toISOString(),
        message,
        data
      });
      // Keep only the last 50 logs
      localStorage.setItem('api_logs', JSON.stringify(logs.slice(0, 50)));
    } catch (e) {
      console.error('Error saving log to localStorage', e);
    }
  },
  error: (message, data) => {
    console.error(`[ERROR] ${message}`, data);
    // Also log to a persistent storage
    try {
      const logs = JSON.parse(localStorage.getItem('api_logs') || '[]');
      logs.unshift({
        level: 'error',
        timestamp: new Date().toISOString(),
        message,
        data
      });
      // Keep only the last 50 logs
      localStorage.setItem('api_logs', JSON.stringify(logs.slice(0, 50)));
    } catch (e) {
      console.error('Error saving log to localStorage', e);
    }
  },
  warn: (message, data) => {
    console.warn(`[WARN] ${message}`, data);
    // Also log to a persistent storage
    try {
      const logs = JSON.parse(localStorage.getItem('api_logs') || '[]');
      logs.unshift({
        level: 'warn',
        timestamp: new Date().toISOString(),
        message,
        data
      });
      // Keep only the last 50 logs
      localStorage.setItem('api_logs', JSON.stringify(logs.slice(0, 50)));
    } catch (e) {
      console.error('Error saving log to localStorage', e);
    }
  }
};

// Generate a unique request ID
const generateRequestId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
};

const axiosInstance = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  // Increased timeout to match nginx proxy timeout (120 seconds)
  timeout: 60000,
  // Add retry configuration
  retry: 3,
  retryDelay: 1000,
});

// Add request interceptor for authentication and logging
axiosInstance.interceptors.request.use(
  (config) => {
    // Generate a unique request ID for tracking
    const requestId = generateRequestId();
    config.requestId = requestId;
    
    // Add timing information
    config.metadata = { startTime: new Date() };
    
    // Add authentication token if available
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Log the request
    logger.log('API Request', {
      requestId,
      method: config.method,
      url: config.url,
      headers: config.headers,
      data: config.data,
      timestamp: new Date().toISOString()
    });
    
    return config;
  },
  (error) => {
    logger.error('Request error', {
      error: error.message,
      stack: error.stack,
      timestamp: new Date().toISOString()
    });
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling and logging
axiosInstance.interceptors.response.use(
  (response) => {
    // Calculate request duration
    const duration = response.config.metadata ?
      (new Date() - response.config.metadata.startTime) : 0;
    
    // Log successful response with timing
    logger.log('API Response', {
      requestId: response.config.requestId,
      status: response.status,
      statusText: response.statusText,
      duration: `${duration}ms`,
      url: response.config.url,
      method: response.config.method,
      timestamp: new Date().toISOString()
    });
    
    // Log slow responses as warnings
    if (duration > 5000) {
      logger.warn('Slow API Response', {
        requestId: response.config.requestId,
        duration: `${duration}ms`,
        url: response.config.url,
        method: response.config.method,
        timestamp: new Date().toISOString()
      });
    }
    
    return response;
  },
  (error) => {
    // Calculate request duration even for errors
    const duration = error.config?.metadata ?
      (new Date() - error.config.metadata.startTime) : 0;
    
    // Get the original request configuration
    const originalConfig = error.config;
    
    // Initialize retry count if it doesn't exist
    if (originalConfig && !originalConfig._retry) {
      originalConfig._retry = 0;
    }
    
    // Check if we should retry the request
    const shouldRetry = originalConfig &&
                        originalConfig._retry < (originalConfig.retry || 3) &&
                        (error.code === 'ECONNABORTED' ||
                         error.message === 'Network Error' ||
                         error.response?.status === 504 ||
                         error.response?.status === 502 ||
                         error.response?.status === 503);
    
    // Handle timeout errors specifically
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      logger.error('API Request Timeout', {
        requestId: error.config?.requestId,
        url: error.config?.url,
        method: error.config?.method,
        timeout: error.config?.timeout,
        duration: `${duration}ms`,
        retryCount: originalConfig?._retry,
        timestamp: new Date().toISOString()
      });
      
      error.isTimeout = true;
      error.friendlyMessage = 'The request timed out. Please try again.';
    }
    // Handle network errors
    else if (error.message === 'Network Error') {
      logger.error('API Network Error', {
        requestId: error.config?.requestId,
        url: error.config?.url,
        method: error.config?.method,
        duration: `${duration}ms`,
        retryCount: originalConfig?._retry,
        timestamp: new Date().toISOString()
      });
      
      error.isNetworkError = true;
      error.friendlyMessage = 'Unable to connect to the server. Please check your internet connection.';
    }
    // Handle other errors
    else {
      logger.error('API Response Error', {
        requestId: error.config?.requestId,
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        url: error.config?.url,
        method: error.config?.method,
        duration: `${duration}ms`,
        retryCount: originalConfig?._retry,
        error: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString()
      });
    }
    
    // Handle authentication errors
    if (error.response?.status === 401) {
      // Clear token and reload on authentication error
      localStorage.removeItem('token');
      window.location.reload();
    }
    
    // Handle gateway timeout errors
    if (error.response?.status === 504) {
      logger.error('Gateway Timeout Error', {
        requestId: error.config?.requestId,
        url: error.config?.url,
        method: error.config?.method,
        duration: `${duration}ms`,
        retryCount: originalConfig?._retry,
        timestamp: new Date().toISOString()
      });
      
      error.isGatewayTimeout = true;
      error.friendlyMessage = 'The server took too long to respond. Please try again later.';
    }
    
    // Implement retry logic for specific errors
    if (shouldRetry) {
      originalConfig._retry += 1;
      
      // Calculate exponential backoff delay
      const delay = originalConfig.retryDelay || 1000;
      const backoffDelay = delay * Math.pow(2, originalConfig._retry - 1);
      
      logger.warn(`Retrying request (${originalConfig._retry}/${originalConfig.retry || 3})`, {
        requestId: originalConfig.requestId,
        url: originalConfig.url,
        method: originalConfig.method,
        delay: backoffDelay,
        timestamp: new Date().toISOString()
      });
      
      // Return a new promise that resolves after the backoff delay
      return new Promise(resolve => {
        setTimeout(() => {
          // Create a new request with the same config
          resolve(axiosInstance(originalConfig));
        }, backoffDelay);
      });
    }
    
    return Promise.reject(error);
  }
);

// Add a method to retrieve logs
axiosInstance.getLogs = () => {
  try {
    return JSON.parse(localStorage.getItem('api_logs') || '[]');
  } catch (e) {
    console.error('Error retrieving logs', e);
    return [];
  }
};

// Add a method to clear logs
axiosInstance.clearLogs = () => {
  localStorage.removeItem('api_logs');
};

export default axiosInstance;