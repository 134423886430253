import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useTheme,
} from '@mui/material';
import {
  Email as EmailIcon,
  Sms as SmsIcon,
  NotificationsActive as PushIcon,
  NotificationsActive,
} from '@mui/icons-material';
import axiosInstance from '../api/axios';

// UUID validation regex
const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const TestNotifications = ({ authToken }) => {
  const theme = useTheme();
  const [userId, setUserId] = useState('');
  const [message, setMessage] = useState('');
  const [methods, setMethods] = useState({
    push: true,
    email: true,
    sms: true,
  });
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const isValidUUID = (uuid) => {
    return uuidRegex.test(uuid.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedUserId = userId.trim();

    if (!sanitizedUserId || !message) {
      setFeedback({
        type: 'error',
        message: 'Please enter both User ID and Message.',
      });
      return;
    }

    if (!isValidUUID(sanitizedUserId)) {
      setFeedback({
        type: 'error',
        message: 'Invalid User ID format. Please enter a valid UUID.',
      });
      return;
    }

    setLoading(true);
    setFeedback(null);

    try {
      await axiosInstance.post(
        '/notification/test',
        { userId: sanitizedUserId, message, methods },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setFeedback({
        type: 'success',
        message: 'Test notification sent successfully!',
      });
      setMessage(''); // Clear message after success
    } catch (error) {
      const errorMsg = error.response?.data?.error || error.response?.data?.message || 'Unknown error occurred.';
      setFeedback({
        type: 'error',
        message: `Failed to send test notification: ${errorMsg}`,
      });
      console.error('Send Test Notification Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setMethods({
      ...methods,
      [event.target.name]: event.target.checked,
    });
  };

  const getIcon = () => {
    if (methods.email && !methods.sms && !methods.push) return <EmailIcon sx={{ fontSize: 40 }} />;
    if (!methods.email && methods.sms && !methods.push) return <SmsIcon sx={{ fontSize: 40 }} />;
    if (!methods.email && !methods.sms && methods.push) return <PushIcon sx={{ fontSize: 40 }} />;
    return <NotificationsActive sx={{ fontSize: 40 }} />;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Test Notifications
      </Typography>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                {getIcon()}
                <Typography variant="h5" sx={{ ml: 2 }}>
                  Send Test Notification
                </Typography>
              </Box>

              {feedback && (
                <Alert 
                  severity={feedback.type}
                  sx={{ mb: 2 }}
                  onClose={() => setFeedback(null)}
                >
                  {feedback.message}
                </Alert>
              )}

              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="User ID"
                  placeholder="Enter User UUID"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  sx={{ mb: 2 }}
                  required
                />

                <TextField
                  fullWidth
                  label="Message"
                  placeholder="Enter notification message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                  required
                />

                <FormGroup sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={methods.push}
                        onChange={handleCheckboxChange}
                        name="push"
                      />
                    }
                    label="Push Notification"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={methods.email}
                        onChange={handleCheckboxChange}
                        name="email"
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={methods.sms}
                        onChange={handleCheckboxChange}
                        name="sms"
                      />
                    }
                    label="SMS"
                  />
                </FormGroup>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={loading}
                  sx={{ minWidth: 200 }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: 'white' }} />
                  ) : (
                    'Send Test Notification'
                  )}
                </Button>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TestNotifications;
