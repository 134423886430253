import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Chip,
  Alert,
  CircularProgress,
  Tooltip,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Security as SecurityIcon,
  Key as KeyIcon
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';

const UserManagement = () => {
  const { getUsers, createUser, updateUser, resetUserPassword, resetUserMFA, currentUser } = useAuth();
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Dialog states
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [openResetMFADialog, setOpenResetMFADialog] = useState(false);
  
  // Form states
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'user',
    active: true,
    mfaEnabled: false
  });
  
  // Load users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);
  
  // Fetch users from API
  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      setError('Failed to load users. Please try again.');
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'active' || name === 'mfaEnabled' ? checked : value
    });
  };
  
  // Open add user dialog
  const handleOpenAddDialog = () => {
    setFormData({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: 'user',
      active: true,
      mfaEnabled: false
    });
    setOpenAddDialog(true);
  };
  
  // Open edit user dialog
  const handleOpenEditDialog = (user) => {
    setSelectedUser(user);
    setFormData({
      name: user.name,
      email: user.email,
      password: '',
      confirmPassword: '',
      role: user.role,
      active: user.active,
      mfaEnabled: user.mfaEnabled
    });
    setOpenEditDialog(true);
  };
  
  // Open delete user dialog
  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };
  
  // Open reset password dialog
  const handleOpenResetPasswordDialog = (user) => {
    setSelectedUser(user);
    setFormData({
      ...formData,
      password: '',
      confirmPassword: ''
    });
    setOpenResetPasswordDialog(true);
  };
  
  // Open reset MFA dialog
  const handleOpenResetMFADialog = (user) => {
    setSelectedUser(user);
    setOpenResetMFADialog(true);
  };
  
  // Close all dialogs
  const handleCloseDialogs = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenResetPasswordDialog(false);
    setOpenResetMFADialog(false);
    setSelectedUser(null);
    setError('');
  };
  
  // Validate form data
  const validateForm = (isPasswordRequired = true) => {
    if (!formData.name) {
      setError('Name is required');
      return false;
    }
    
    if (!formData.email) {
      setError('Email is required');
      return false;
    }
    
    if (isPasswordRequired && !formData.password) {
      setError('Password is required');
      return false;
    }
    
    if (isPasswordRequired && formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    
    return true;
  };
  
  // Add new user
  const handleAddUser = async () => {
    if (!validateForm()) return;
    
    setLoading(true);
    setError('');
    
    try {
      await createUser({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role,
        active: formData.active,
        mfaEnabled: formData.mfaEnabled
      });
      
      await fetchUsers();
      setSuccess('User created successfully');
      handleCloseDialogs();
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to create user');
      console.error('Error creating user:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Update existing user
  const handleUpdateUser = async () => {
    if (!validateForm(false)) return;
    
    setLoading(true);
    setError('');
    
    try {
      const userData = {
        name: formData.name,
        email: formData.email,
        role: formData.role,
        active: formData.active,
        mfaEnabled: formData.mfaEnabled
      };
      
      // Only include password if it's provided
      if (formData.password) {
        if (formData.password !== formData.confirmPassword) {
          setError('Passwords do not match');
          setLoading(false);
          return;
        }
        userData.password = formData.password;
      }
      
      await updateUser(selectedUser.id, userData);
      
      await fetchUsers();
      setSuccess('User updated successfully');
      handleCloseDialogs();
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update user');
      console.error('Error updating user:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Delete user (deactivate)
  const handleDeleteUser = async () => {
    setLoading(true);
    setError('');
    
    try {
      await updateUser(selectedUser.id, {
        active: false
      });
      
      await fetchUsers();
      setSuccess('User deactivated successfully');
      handleCloseDialogs();
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to deactivate user');
      console.error('Error deactivating user:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Reset user password
  const handleResetPassword = async () => {
    if (!formData.password) {
      setError('New password is required');
      return;
    }
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      await resetUserPassword(selectedUser.id, formData.password);
      
      setSuccess('Password reset successfully');
      handleCloseDialogs();
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to reset password');
      console.error('Error resetting password:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Reset user MFA
  const handleResetMFA = async () => {
    setLoading(true);
    setError('');
    
    try {
      await resetUserMFA(selectedUser.id);
      
      await fetchUsers();
      setSuccess('MFA reset successfully');
      handleCloseDialogs();
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to reset MFA');
      console.error('Error resetting MFA:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Clear success message after 5 seconds
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess('');
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [success]);
  
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          User Management
        </Typography>
        
        <Box>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={fetchUsers}
            sx={{ mr: 1 }}
          >
            Refresh
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenAddDialog}
          >
            Add User
          </Button>
        </Box>
      </Box>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>MFA</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress size={40} sx={{ my: 2 }} />
                  </TableCell>
                </TableRow>
              ) : users.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No users found
                  </TableCell>
                </TableRow>
              ) : (
                users.map((user) => (
                  <TableRow key={user.id} hover>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Chip
                        label={user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                        color={user.role === 'admin' ? 'primary' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={user.active ? 'Active' : 'Inactive'}
                        color={user.active ? 'success' : 'error'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={user.mfaEnabled ? 'Enabled' : 'Disabled'}
                        color={user.mfaEnabled ? 'info' : 'default'}
                        size="small"
                        icon={user.mfaEnabled ? <SecurityIcon fontSize="small" /> : null}
                      />
                    </TableCell>
                    <TableCell>
                      {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Never'}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit User">
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditDialog(user)}
                          disabled={user.id === currentUser?.id} // Can't edit self
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      
                      <Tooltip title="Reset Password">
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenResetPasswordDialog(user)}
                          disabled={user.id === currentUser?.id} // Can't reset own password here
                        >
                          <KeyIcon />
                        </IconButton>
                      </Tooltip>
                      
                      {user.mfaEnabled && (
                        <Tooltip title="Reset MFA">
                          <IconButton
                            color="warning"
                            onClick={() => handleOpenResetMFADialog(user)}
                            disabled={user.id === currentUser?.id} // Can't reset own MFA here
                          >
                            <SecurityIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      
                      <Tooltip title="Deactivate User">
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDeleteDialog(user)}
                          disabled={user.id === currentUser?.id} // Can't delete self
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      
      {/* Add User Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseDialogs} maxWidth="sm" fullWidth>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            value={formData.password}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          <TextField
            margin="dense"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={formData.role}
              onChange={handleInputChange}
              label="Role"
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.active}
                  onChange={handleInputChange}
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={formData.mfaEnabled}
                  onChange={handleInputChange}
                  name="mfaEnabled"
                  color="primary"
                />
              }
              label="Require MFA"
            />
          </Box>
          
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button
            onClick={handleAddUser}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Add User'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Edit User Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseDialogs} maxWidth="sm" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Full Name"
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Leave password fields empty to keep current password
          </Typography>
          
          <TextField
            margin="dense"
            name="password"
            label="New Password"
            type="password"
            fullWidth
            value={formData.password}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          
          <TextField
            margin="dense"
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={formData.confirmPassword}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          
          <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              value={formData.role}
              onChange={handleInputChange}
              label="Role"
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.active}
                  onChange={handleInputChange}
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={formData.mfaEnabled}
                  onChange={handleInputChange}
                  name="mfaEnabled"
                  color="primary"
                />
              }
              label="Require MFA"
            />
          </Box>
          
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button
            onClick={handleUpdateUser}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Update User'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete User Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDialogs}>
        <DialogTitle>Deactivate User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate {selectedUser?.name}? This will prevent them from logging in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button
            onClick={handleDeleteUser}
            variant="contained"
            color="error"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Deactivate'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Reset Password Dialog */}
      <Dialog open={openResetPasswordDialog} onClose={handleCloseDialogs} maxWidth="sm" fullWidth>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set a new password for {selectedUser?.name}.
          </DialogContentText>
          
          <TextField
            margin="dense"
            name="password"
            label="New Password"
            type="password"
            fullWidth
            value={formData.password}
            onChange={handleInputChange}
            required
            sx={{ mb: 2, mt: 2 }}
          />
          
          <TextField
            margin="dense"
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button
            onClick={handleResetPassword}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Reset Password'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Reset MFA Dialog */}
      <Dialog open={openResetMFADialog} onClose={handleCloseDialogs}>
        <DialogTitle>Reset MFA</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset MFA for {selectedUser?.name}? This will disable MFA for their account and they will need to set it up again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button
            onClick={handleResetMFA}
            variant="contained"
            color="warning"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Reset MFA'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;