import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Chip,
  Box,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Grid,
  TablePagination
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import axiosInstance from '../api/axios';
import dayjs from 'dayjs';

function NotificationsLog({ authToken }) {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    status: 'all',
    method: 'all',
    type: 'all'
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchLogs = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/notification/logs', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setLogs(response.data);
    } catch (error) {
      setError('Failed to fetch notification logs. Please try again later.');
      console.error('Fetch Logs Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'success':
        return 'success';
      case 'failed':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getMethodColor = (method) => {
    switch (method.toLowerCase()) {
      case 'email':
        return 'primary';
      case 'sms':
        return 'secondary';
      case 'push':
        return 'info';
      default:
        return 'default';
    }
  };

  const filteredLogs = logs.filter(log => {
    return (
      (filters.status === 'all' || log.status.toLowerCase() === filters.status) &&
      (filters.method === 'all' || log.notification_method.toLowerCase() === filters.method) &&
      (filters.type === 'all' || log.notification_type.toLowerCase() === filters.type)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const uniqueTypes = [...new Set(logs.map(log => log.notification_type))];

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">
          Notification History
        </Typography>
        <Tooltip title="Refresh">
          <IconButton onClick={fetchLogs} disabled={loading}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Status"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            size="small"
          >
            <MenuItem value="all">All Statuses</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Method"
            value={filters.method}
            onChange={(e) => setFilters({ ...filters, method: e.target.value })}
            size="small"
          >
            <MenuItem value="all">All Methods</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="sms">SMS</MenuItem>
            <MenuItem value="push">Push</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            label="Type"
            value={filters.type}
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            size="small"
          >
            <MenuItem value="all">All Types</MenuItem>
            {uniqueTypes.map(type => (
              <MenuItem key={type} value={type.toLowerCase()}>{type}</MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : filteredLogs.length > 0 ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((log) => (
                    <TableRow key={log.id} hover>
                      <TableCell>
                        {dayjs(log.timestamp).format('MMM D, YYYY h:mm A')}
                      </TableCell>
                      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {log.user_id}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={log.notification_type}
                          size="small"
                          color="info"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={log.notification_method}
                          size="small"
                          color={getMethodColor(log.notification_method)}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={log.status}
                          size="small"
                          color={getStatusColor(log.status)}
                        />
                      </TableCell>
                      <TableCell sx={{ maxWidth: 300 }}>
                        {log.message}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredLogs.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      ) : (
        <Box textAlign="center" py={3}>
          <Typography color="textSecondary">
            No notification logs found.
          </Typography>
        </Box>
      )}
    </Paper>
  );
}

export default NotificationsLog;
