import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  CircularProgress,
  Tooltip,
  IconButton,
  Chip,
  Divider
} from '@mui/material';
import { Info as InfoIcon, Schedule as ScheduleIcon } from '@mui/icons-material';
import axiosInstance from '../api/axios';

const cronExamples = [
  { example: '0 0 * * *', description: 'Every day at midnight' },
  { example: '0 8 * * *', description: 'Every day at 8 AM' },
  { example: '0 */4 * * *', description: 'Every 4 hours' },
  { example: '0 9 * * 1-5', description: 'Every weekday at 9 AM' }
];

// Helper function to parse cron expression into human-readable format
const parseCronToHumanReadable = (cronExpression) => {
  const parts = cronExpression.split(' ');
  if (parts.length !== 5) return 'Invalid cron expression';
  
  const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;
  
  // Simple parsing for common patterns
  if (minute === '0' && hour === '0' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return 'Every day at midnight';
  }
  
  if (minute === '0' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    if (hour.includes('*/')) {
      const interval = hour.split('/')[1];
      return `Every ${interval} hours`;
    } else {
      return `Every day at ${hour}:00`;
    }
  }
  
  if (minute === '0' && dayOfMonth === '*' && month === '*' && dayOfWeek === '1-5') {
    return `Every weekday at ${hour}:00`;
  }
  
  // Default case
  return `Cron: ${cronExpression}`;
};

// Helper function to convert cron time to AEDT
const getCronTimeInAEDT = (cronExpression) => {
  const parts = cronExpression.split(' ');
  if (parts.length !== 5) return 'Invalid cron expression';
  
  const [minute, hour] = parts;
  
  // Only handle simple hour conversions
  if (hour.includes('*/') || hour === '*') {
    return 'Variable time';
  }
  
  // Convert to 24-hour format with leading zero
  const hourNum = parseInt(hour, 10);
  const formattedHour = hourNum.toString().padStart(2, '0');
  const formattedMinute = minute.toString().padStart(2, '0');
  
  return `${formattedHour}:${formattedMinute} AEDT`;
};

function ScheduleSettings({ authToken }) {
  const [cronTime, setCronTime] = useState('');
  const [currentSchedule, setCurrentSchedule] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [feedback, setFeedback] = useState(null);
  const [showExamples, setShowExamples] = useState(false);

  // Fetch current schedule when component mounts
  useEffect(() => {
    const fetchCurrentSchedule = async () => {
      setFetchLoading(true);
      try {
        const response = await axiosInstance.get('/notification/schedule', {
          headers: { Authorization: `Bearer ${authToken}` }
        });
        
        if (response.data && response.data.currentSchedule) {
          setCurrentSchedule(response.data.currentSchedule);
        }
      } catch (error) {
        console.error('Error fetching current schedule:', error);
        setFeedback({
          type: 'warning',
          message: 'Could not fetch current schedule. Using default.'
        });
      } finally {
        setFetchLoading(false);
      }
    };
    
    fetchCurrentSchedule();
  }, [authToken]);

  const validateCronExpression = (cron) => {
    // Basic cron validation - can be enhanced
    const parts = cron.split(' ');
    if (parts.length !== 5) return false;
    return true;
  };

  const handleSubmit = async () => {
    if (!cronTime) {
      setFeedback({ type: 'error', message: 'Please enter a Cron Time' });
      return;
    }

    if (!validateCronExpression(cronTime)) {
      setFeedback({ type: 'error', message: 'Invalid Cron expression format' });
      return;
    }

    setLoading(true);
    setFeedback(null);

    try {
      await axiosInstance.post('/notification/schedule',
        { newCronTime: cronTime },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      
      // Update the current schedule after successful update
      setCurrentSchedule(cronTime);
      
      setFeedback({
        type: 'success',
        message: 'Schedule time updated successfully'
      });
      // Don't clear the input to allow for minor adjustments
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.response?.data?.message || 'Failed to update schedule time'
      });
      console.error('Update Schedule Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h5" component="h2">
          Notification Schedule
        </Typography>
        <Tooltip title="Toggle Cron Examples">
          <IconButton
            size="small"
            onClick={() => setShowExamples(!showExamples)}
            sx={{ ml: 1 }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Typography color="textSecondary" paragraph>
        Set the schedule for when notifications should be processed using Cron syntax.
      </Typography>

      {/* Current Schedule Display */}
      {currentSchedule && (
        <Box mb={3} p={2} bgcolor="background.paper" borderRadius={1} border={1} borderColor="divider">
          <Box display="flex" alignItems="center" mb={1}>
            <ScheduleIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Current Schedule</Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />
          
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', minWidth: 100 }}>
                Cron Expression:
              </Typography>
              <Chip
                label={currentSchedule}
                variant="outlined"
                color="primary"
                size="small"
              />
            </Box>
            
            <Box display="flex" alignItems="center">
              <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', minWidth: 100 }}>
                Human Readable:
              </Typography>
              <Typography variant="body2">
                {parseCronToHumanReadable(currentSchedule)}
              </Typography>
            </Box>
            
            <Box display="flex" alignItems="center">
              <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', minWidth: 100 }}>
                AEDT Time:
              </Typography>
              <Typography variant="body2" color="primary.main" fontWeight="medium">
                {getCronTimeInAEDT(currentSchedule)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      
      {fetchLoading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress size={24} />
        </Box>
      )}

      {showExamples && (
        <Box mb={3} p={2} bgcolor="background.default" borderRadius={1}>
          <Typography variant="subtitle2" gutterBottom>
            Common Cron Examples:
          </Typography>
          {cronExamples.map((item, index) => (
            <Box key={index} mb={1}>
              <Typography variant="body2" component="code" sx={{ bgcolor: 'grey.100', p: 0.5 }}>
                {item.example}
              </Typography>
              <Typography variant="body2" component="span" sx={{ ml: 2 }}>
                - {item.description}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {feedback && (
        <Alert 
          severity={feedback.type} 
          sx={{ mb: 2 }}
          onClose={() => setFeedback(null)}
        >
          {feedback.message}
        </Alert>
      )}

      <Box component="form" noValidate>
        <TextField
          fullWidth
          label="Cron Time"
          placeholder="e.g., 0 0 * * *"
          value={cronTime}
          onChange={(e) => setCronTime(e.target.value)}
          margin="normal"
          helperText="Enter schedule in Cron format (minute hour day month weekday)"
          disabled={loading}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Update Schedule'
          )}
        </Button>
      </Box>
    </Paper>
  );
}

export default ScheduleSettings;
