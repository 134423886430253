import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  Paper,
  Tooltip,
} from '@mui/material';
import {
  Memory as MemoryIcon,
  Speed as SpeedIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import { useQuery } from 'react-query';
import axiosInstance from '../api/axios';

// Helper function to format uptime in a human-readable format
const formatUptime = (seconds) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  
  const parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  
  return parts.join(' ') || '< 1m';
};

const SystemMetrics = ({ authToken }) => {
  // Fetch metrics data
  const { data: metrics, isLoading: metricsLoading, error: metricsError } = useQuery(
    'metrics',
    async () => {
      const response = await axiosInstance.get('/metrics', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    },
    { refetchInterval: 30000 }
  );

  // Fetch service health data
  const { data: serviceHealth, isLoading: healthLoading, error: healthError } = useQuery(
    'serviceHealth',
    async () => {
      const response = await axiosInstance.get('/health/services', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    },
    { refetchInterval: 30000 }
  );

  if (metricsLoading || healthLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (metricsError || healthError) {
    return (
      <Alert severity="error">
        Failed to fetch system metrics. Please try again later.
      </Alert>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        System Metrics
      </Typography>

      <Grid container spacing={3}>
        {/* System Resource Metrics */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                System Resources
              </Typography>
              <Grid container spacing={3}>
                {/* Memory Usage */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <MemoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="h6">Memory Usage</Typography>
                    </Box>
                    
                    {metrics?.systemHealth?.system?.memory && (
                      <>
                        <Box sx={{ width: '100%', mb: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={(metrics.systemHealth.system.memory.used / metrics.systemHealth.system.memory.total) * 100}
                            sx={{ height: 10, borderRadius: 5 }}
                          />
                        </Box>
                        
                        <Typography variant="body2" color="text.secondary">
                          {`${Math.round(metrics.systemHealth.system.memory.used / 1024 / 1024)} MB / ${Math.round(metrics.systemHealth.system.memory.total / 1024 / 1024)} MB`}
                        </Typography>
                        
                        <Typography variant="body2" color="text.secondary">
                          {`${Math.round((metrics.systemHealth.system.memory.used / metrics.systemHealth.system.memory.total) * 100)}% Used`}
                        </Typography>
                      </>
                    )}
                  </Paper>
                </Grid>
                
                {/* CPU Load */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <SpeedIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="h6">CPU Load</Typography>
                    </Box>
                    
                    {metrics?.systemHealth?.system?.cpu && (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {metrics.systemHealth.system.cpu.loadAvg[0].toFixed(2)}
                          </Typography>
                          
                          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 1 }}>
                            Load Average (1 min)
                          </Typography>
                          
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                              5 min: {metrics.systemHealth.system.cpu.loadAvg[1].toFixed(2)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              15 min: {metrics.systemHealth.system.cpu.loadAvg[2].toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Paper>
                </Grid>
                
                {/* System Uptime */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <AccessTimeIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="h6">System Uptime</Typography>
                    </Box>
                    
                    {metrics?.systemHealth?.system?.uptime && (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {formatUptime(metrics.systemHealth.system.uptime)}
                          </Typography>
                          
                          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 1 }}>
                            Since {new Date(Date.now() - (metrics.systemHealth.system.uptime * 1000)).toLocaleString()}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Database Performance Metrics */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Database Performance
              </Typography>
              <Grid container spacing={3}>
                {/* Database Connections */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography variant="h6">Connections</Typography>
                    </Box>
                    
                    {metrics?.systemHealth?.database?.metrics && (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {metrics.systemHealth.database.metrics.connections || 0}
                          </Typography>
                          
                          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 1 }}>
                            Total Database Connections
                          </Typography>
                          
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                              Active Queries: {metrics.systemHealth.database.metrics.activeQueries || 0}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Paper>
                </Grid>
                
                {/* Query Performance */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography variant="h6">Query Performance</Typography>
                    </Box>
                    
                    {metrics?.systemHealth?.database?.metrics && (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {metrics.systemHealth.database.metrics.avgQueryTime?.toFixed(2) || '0.00'} ms
                          </Typography>
                          
                          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 1 }}>
                            Average Query Time
                          </Typography>
                          
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                              Query Count: {metrics.systemHealth.database.metrics.queryCount || 0}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Paper>
                </Grid>
                
                {/* Database Status */}
                <Grid item xs={12} md={4}>
                  <Paper sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    bgcolor: metrics?.systemHealth?.database?.connected ? 'success.light' : 'error.light'
                  }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography variant="h6">Database Status</Typography>
                    </Box>
                    
                    {metrics?.systemHealth?.database && (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {metrics.systemHealth.database.connected ? 'Connected' : 'Disconnected'}
                          </Typography>
                          
                          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                            Last Check: {
                              metrics.systemHealth.database.lastCheck
                                ? new Date(metrics.systemHealth.database.lastCheck).toLocaleTimeString()
                                : 'Unknown'
                            }
                          </Typography>
                          
                          {metrics.systemHealth.database.metrics.reconnectionAttempts > 0 && (
                            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
                              Reconnection Attempts: {metrics.systemHealth.database.metrics.reconnectionAttempts}
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Notification Statistics */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Notification Statistics
              </Typography>
              <List>
                <ListItem>
                  <ListItemText 
                    primary="Total Notifications" 
                    secondary={metrics?.notificationStats.total || 0} 
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Success Rate" 
                    secondary={`${((metrics?.notificationStats.success / metrics?.notificationStats.total) * 100 || 0).toFixed(2)}%`} 
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText 
                    primary="By Method" 
                    secondary={
                      <Box>
                        {Object.entries(metrics?.notificationStats.byMethod || {}).map(([method, stats]) => (
                          <Typography key={method} variant="body2">
                            {`${method}: ${stats.sent} sent, ${stats.failed} failed`}
                          </Typography>
                        ))}
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Service Health */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Service Health
              </Typography>
              <List>
                {serviceHealth?.services.map((service) => (
                  <ListItem key={service.service}>
                    <ListItemText
                      primary={service.service}
                      secondary={
                        <Typography
                          component="span"
                          sx={{
                            color: service.status === 'healthy' ? 'success.main' : 'error.main',
                          }}
                        >
                          {service.status}
                          {service.error && ` - ${service.error}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* External Service Performance */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                External Service Performance
              </Typography>
              <Grid container spacing={3}>
                {metrics?.systemHealth?.externalServices && Object.entries(metrics.systemHealth.externalServices).map(([serviceName, serviceData]) => (
                  <Grid item xs={12} md={6} key={serviceName}>
                    <Paper sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      bgcolor: serviceData.status === 'healthy' ? 'success.light' :
                               serviceData.status === 'degraded' ? 'warning.light' : 'error.light'
                    }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{serviceName}</Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {serviceData.status === 'healthy' ? 'Operational' :
                           serviceData.status === 'degraded' ? 'Degraded' : 'Down'}
                        </Typography>
                      </Box>
                      
                      <Divider sx={{ my: 1 }} />
                      
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="body2">
                          Success Rate: {serviceData.metrics?.successRate?.toFixed(2) || 0}%
                        </Typography>
                        
                        <Typography variant="body2">
                          Avg Response Time: {serviceData.metrics?.avgResponseTime?.toFixed(2) || 0} ms
                        </Typography>
                        
                        <Typography variant="body2">
                          Last Check: {serviceData.lastCheck ? new Date(serviceData.lastCheck).toLocaleTimeString() : 'Unknown'}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Error logs section removed - endpoint not implemented */}
      </Grid>
    </Box>
  );
};

export default SystemMetrics;