import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  TextField,
  Collapse,
  Tab,
  Tabs
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Storage as StorageIcon,
  Refresh as RefreshIcon,
  RestartAlt as RestartAltIcon,
  ContentCopy as ContentCopyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  BugReport as BugReportIcon
} from '@mui/icons-material';
import ApiLogViewer from './ApiLogViewer';
import { useQuery, useMutation } from 'react-query';
import { useState } from 'react';
import axiosInstance from '../api/axios';

const Dashboard = ({ authToken }) => {
  const [reconnectDialogOpen, setReconnectDialogOpen] = useState(false);
  const [reconnectResult, setReconnectResult] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [showDetailedLogs, setShowDetailedLogs] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const { data: metrics, isLoading, error, refetch } = useQuery(
    'dashboardMetrics',
    async () => {
      const response = await axiosInstance.get('/metrics', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    },
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
    }
  );
  
  // Database reconnection mutation
  const reconnectMutation = useMutation(
    async () => {
      const response = await axiosInstance.post('/health/database/reconnect', {}, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        setReconnectResult(data);
        setSnackbarMessage(data.success ? 'Database reconnection successful!' : 'Database reconnection failed');
        setSnackbarSeverity(data.success ? 'success' : 'error');
        setSnackbarOpen(true);
        refetch(); // Refresh dashboard data
      },
      onError: (error) => {
        setReconnectResult({
          success: false,
          message: 'Error attempting to reconnect',
          error: error.message,
          connectionInfo: {
            error: {
              message: error.response?.data?.message || error.message,
              stack: error.stack
            }
          }
        });
        setSnackbarMessage('Error attempting to reconnect to database');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  );
  
  const handleReconnectClick = () => {
    setReconnectDialogOpen(true);
    setReconnectResult(null);
  };
  
  const handleConfirmReconnect = () => {
    reconnectMutation.mutate();
  };
  
  const handleCloseDialog = () => {
    setReconnectDialogOpen(false);
  };
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  
  const copyLogsToClipboard = () => {
    if (reconnectResult) {
      const logData = JSON.stringify(reconnectResult, null, 2);
      navigator.clipboard.writeText(logData)
        .then(() => {
          setSnackbarMessage('Logs copied to clipboard');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch(err => {
          setSnackbarMessage('Failed to copy logs');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        Error loading dashboard data. Please try again later.
      </Alert>
    );
  }

  const notificationStats = metrics?.notificationStats || {
    total: 0,
    success: 0,
    failed: 0,
  };
  
  // Ensure backward compatibility with older metrics structure
  if (metrics?.systemHealth?.databaseConnected !== undefined && metrics.systemHealth.database === undefined) {
    metrics.systemHealth.database = {
      connected: metrics.systemHealth.databaseConnected
    };
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">System Overview</Typography>
        <Tooltip title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="dashboard tabs">
          <Tab
            label="System Status"
            icon={<StorageIcon />}
            iconPosition="start"
          />
          <Tab
            label="API Logs"
            icon={<BugReportIcon />}
            iconPosition="start"
          />
        </Tabs>
      </Box>

      {/* Tab Content */}
      {activeTab === 0 && (
        <Grid container spacing={3}>
        {/* Database Status */}
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              bgcolor: metrics?.systemHealth?.database?.connected
                ? 'success.dark'
                : 'error.dark',
            }}
          >
            <Box display="flex" alignItems="center" mb={1}>
              <StorageIcon sx={{ fontSize: 40, color: 'white', mr: 2 }} />
              <Box flexGrow={1}>
                <Typography variant="h6" color="common.white">
                  Database
                </Typography>
                <Typography color="common.white">
                  {metrics?.systemHealth?.database?.connected ? 'Connected' : 'Disconnected'}
                </Typography>
              </Box>
              <Tooltip title="Force Database Reconnect">
                <IconButton
                  size="small"
                  onClick={handleReconnectClick}
                  sx={{
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.2)'
                    }
                  }}
                  disabled={reconnectMutation.isLoading}
                >
                  {reconnectMutation.isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <RestartAltIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            
            {/* Show reconnection info if disconnected */}
            {metrics?.systemHealth?.database && !metrics.systemHealth.database.connected && (
              <Box mt={1} px={1}>
                <Typography variant="caption" color="common.white" sx={{ opacity: 0.9 }}>
                  Last check: {
                    metrics.systemHealth.database.lastCheck
                      ? new Date(metrics.systemHealth.database.lastCheck).toLocaleTimeString()
                      : 'Unknown'
                  }
                </Typography>
                
                {metrics.systemHealth.database.lastReconnectAttempt && (
                  <Typography variant="caption" display="block" color="common.white" sx={{ opacity: 0.9 }}>
                    Reconnecting... Last attempt: {
                      new Date(metrics.systemHealth.database.lastReconnectAttempt).toLocaleTimeString()
                    }
                  </Typography>
                )}
                
                {metrics.systemHealth.database.lastError && (
                  <Typography variant="caption" display="block" color="common.white" sx={{ opacity: 0.9 }}>
                    Error: {metrics.systemHealth.database.lastError.message}
                  </Typography>
                )}
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Total Notifications */}
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'primary.dark',
            }}
          >
            <NotificationsIcon sx={{ fontSize: 40, color: 'white', mr: 2 }} />
            <Box>
              <Typography variant="h4" color="common.white">
                {notificationStats.total}
              </Typography>
              <Typography color="common.white">Total Notifications</Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Successful Deliveries */}
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'success.dark',
            }}
          >
            <CheckCircleIcon sx={{ fontSize: 40, color: 'white', mr: 2 }} />
            <Box>
              <Typography variant="h4" color="common.white">
                {notificationStats.success}
              </Typography>
              <Typography color="common.white">Successful Deliveries</Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Failed Deliveries */}
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'error.dark',
            }}
          >
            <ErrorIcon sx={{ fontSize: 40, color: 'white', mr: 2 }} />
            <Box>
              <Typography variant="h4" color="common.white">
                {notificationStats.failed}
              </Typography>
              <Typography color="common.white">Failed Deliveries</Typography>
            </Box>
          </Paper>
        </Grid>

        {/* System Status */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                System Status
              </Typography>
              <Grid container spacing={2}>
                {metrics?.systemHealth?.externalServices && 
                  Object.entries(metrics.systemHealth.externalServices).map(([service, status]) => (
                    <Grid item xs={12} sm={6} md={4} key={service}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          bgcolor: status.status === 'healthy' ? 'success.light' : 'error.light',
                        }}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          {status.status === 'healthy' ? (
                            <CheckCircleIcon sx={{ mr: 1, color: 'success.dark' }} />
                          ) : (
                            <ErrorIcon sx={{ mr: 1, color: 'error.dark' }} />
                          )}
                          <Box flexGrow={1}>
                            <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                              {service}
                            </Typography>
                            <Typography variant="body2">
                              {status.status === 'healthy' ? 'Operational' : 'Issue Detected'}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      )}
      
      {/* API Logs Tab */}
      {activeTab === 1 && (
        <Box sx={{ mt: 2 }}>
          <ApiLogViewer />
        </Box>
      )}

      {/* Reconnect Dialog */}
      <Dialog
        open={reconnectDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {reconnectMutation.isLoading ? 'Reconnecting to Database...' :
           reconnectResult ?
             (reconnectResult.success ? 'Database Reconnection Successful' : 'Database Reconnection Failed') :
             'Confirm Database Reconnection'}
        </DialogTitle>
        <DialogContent>
          {reconnectMutation.isLoading ? (
            <Box display="flex" flexDirection="column" alignItems="center" py={3}>
              <CircularProgress size={60} />
              <Typography variant="body1" sx={{ mt: 2 }}>
                Attempting to reconnect to the database...
              </Typography>
            </Box>
          ) : reconnectResult ? (
            <Box>
              <Alert
                severity={reconnectResult.success ? 'success' : 'error'}
                sx={{ mb: 2 }}
              >
                {reconnectResult.message}
              </Alert>
              
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="subtitle1">Connection Details</Typography>
                <Button
                  startIcon={<ContentCopyIcon />}
                  onClick={copyLogsToClipboard}
                  size="small"
                >
                  Copy Logs
                </Button>
              </Box>
              
              <Box
                sx={{
                  p: 2,
                  bgcolor: 'grey.100',
                  borderRadius: 1,
                  maxHeight: '200px',
                  overflowY: 'auto',
                  mb: 2
                }}
              >
                <Typography variant="body2" component="div" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                  {`Status: ${reconnectResult.success ? 'Connected' : 'Disconnected'}
Timestamp: ${reconnectResult.connectionInfo?.timestamp || new Date().toISOString()}
${reconnectResult.connectionInfo?.version ? `Database Version: ${reconnectResult.connectionInfo.version}` : ''}
${reconnectResult.connectionInfo?.connections ? `Active Connections: ${reconnectResult.connectionInfo.connections}` : ''}`}
                </Typography>
              </Box>
              
              {reconnectResult.connectionInfo?.error && (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => setShowDetailedLogs(!showDetailedLogs)}
                    sx={{ cursor: 'pointer', mb: 1 }}
                  >
                    <Typography variant="subtitle1" color="error">Error Details</Typography>
                    {showDetailedLogs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Box>
                  
                  <Collapse in={showDetailedLogs}>
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: 'error.light',
                        borderRadius: 1,
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }}
                    >
                      <Typography variant="body2" component="div" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                        {`Error: ${reconnectResult.connectionInfo.error.message || 'Unknown error'}
Code: ${reconnectResult.connectionInfo.error.code || 'N/A'}
${reconnectResult.connectionInfo.error.stack ? `Stack: ${reconnectResult.connectionInfo.error.stack}` : ''}`}
                      </Typography>
                    </Box>
                  </Collapse>
                </>
              )}
              
              {!reconnectResult.success && reconnectResult.diagnostics && (
                <Box mt={2}>
                  <Typography variant="subtitle1">System Diagnostics</Typography>
                  <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    value={JSON.stringify(reconnectResult.diagnostics, null, 2)}
                    InputProps={{
                      readOnly: true,
                      sx: { fontFamily: 'monospace', fontSize: '0.875rem' }
                    }}
                    sx={{ mt: 1 }}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Typography>
              Are you sure you want to force a database reconnection attempt? This will attempt to re-establish the connection to the database.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {reconnectMutation.isLoading ? null : (
            <>
              <Button onClick={handleCloseDialog}>
                {reconnectResult ? 'Close' : 'Cancel'}
              </Button>
              {!reconnectResult && (
                <Button
                  onClick={handleConfirmReconnect}
                  variant="contained"
                  color="primary"
                >
                  Reconnect
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Dashboard;
